<template>
  <div v-if="isshowgrid">
    <va-card title="Notification List">
      <div class="row align--center">
        <div class="flex md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex md6 text-right">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card title="Notification">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Title</span>
            <va-input
              v-model="title"
              type="text"
              maxlength="50"
              placeholder="Enter Title"
              :error="!!titleErrors.length"
              :error-messages="titleErrors"
            />
            <span class="va-form-label va-form-required-label">Message</span>
            <va-input
              v-model="message"
              type="text"
              maxlength="100"
              placeholder="Enter Message"
              :error="!!messageErrors.length"
              :error-messages="messageErrors"
            />
            <span class="va-form-label va-form-required-label">Notification Type</span>
            <va-select
              v-model="flag"
              placeholder="Select Notification Type"
              :options="notification_type"
              @input="checkData(flag)"
              :error="!!flagErrors.length"
              :error-messages="flagErrors"
            />
            <span v-if="show_search" class="va-form-label va-form-required-label">Search Content</span>
            <div class="row" v-if="show_search">
              <div class="flex md2">
                <va-select
                  v-model="search_provider"
                  placeholder="Select Provider"
                  textBy="company_name"
                  searchable
                  :options="provider"
                  @input="getServiceType()"
                  :error="!!selectSubscriberErrors.length"
                  :error-messages="selectSubscriberErrors"
                />
              </div>
              <div class="flex md2">
                <va-select
                  v-model="search_serviceType"
                  placeholder="Select Service Type"
                  :options="ServiceType"
                  :error="!!selectSubscriberErrors.length"
                  :error-messages="selectSubscriberErrors"
                />
              </div>
              <div class="flex md2">
                <va-select
                  v-model="search_language"
                  placeholder="Select Language"
                  :options="Language"
                  @input="getLanguagescode()"
                  :error="!!selectSubscriberErrors.length"
                  :error-messages="selectSubscriberErrors"
                />
              </div>
              <div class="flex md2">
                <va-input
                  v-model="search_data"
                  type="text"
                  placeholder="Search Movie Name"
                  :error="!!searchDataErrors.length"
                  :error-messages="searchDataErrors"
                />
              </div>
              <div class="flex md2">
                <va-button type="button" class="my-0" @click.prevent="getContent()">Search</va-button>
              </div>
            </div>
            <span v-if="show_search_title" class="va-form-label va-form-required-label">Select Title</span>
            <va-select
              v-model="select_search_title"
              v-if="show_search_title"
              placeholder="Select Title"
              textBy="title"
              searchable
              @input="getContentId()"
              :options="ServiceTitle"
              :error="!!selectSubscriberErrors.length"
              :error-messages="selectSubscriberErrors"
            />
            <span v-if="show_image" class="va-form-label va-form-required-label">Add Image</span>
            <va-card v-if="show_image">
              <span class="va-form-label va-form-required-label">500 * 300 JPG Image</span>
              <va-file-upload
                type="single"
                dropzone
                @input="checkImage()"
                v-model="adimage_file"
              />
            </va-card>
            <span v-if="!opeartor_role" class="va-form-label va-form-required-label">Select Operator Options</span>
            <va-select
              v-model="operator_delivery_type"
              v-if="!opeartor_role"
              placeholder="Select Delivery Type"
              :options="notification_delivery_type"
              @input="checkOperatorType(delivery_type)"
            />
            <span v-if="show_operators_list" class="va-form-label va-form-required-label">Select Operators</span>
	    <multiselect
              v-if="show_operators_list"
              v-model="opeartor_name"
              placeholder="Select Operators"
              label="org_name"
              track-by="id"
              @input="arrangeOperator()"
              :close-on-select="false"
              :clear-on-select="false"  
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              id="example"
              :multiple="true"
              :options="ope_list"
            />
            <div v-if="selectSubscriberErrors.length > 0" class="errors">{{selectSubscriberErrors[0]}}</div>
            <span v-if="opeartor_role" class="va-form-label va-form-required-label">Notification Send Type</span>
            <va-select
              v-model="delivery_type"
              v-if="opeartor_role"
              placeholder="Select Delivery Type"
              :options="notification_delivery_type"
              @input="checkDeliveryType(delivery_type)"
              :error="!!sendTypeErrors.length"
              :error-messages="sendTypeErrors"
            />
            <span v-if="show_subscriber" class="va-form-label va-form-required-label">Select Subscriber</span>
	    <multiselect
              v-if="show_subscriber"
              v-model="subscriber_name"
              placeholder="Select Subscriber"
              label="mobile"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"  
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              id="example"
              :multiple="true"
              :options="sub_list"
              @input="arrangeSubscriber()"
            />
            <div v-if="selectSubscriberErrors.length > 0" class="errors">{{selectSubscriberErrors[0]}}</div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createNotification()">Create</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'notification',
  created () {
  //   const loader = Vue.$loading.show({ width: 40, height: 40 })
  //   const user_id = Vue.$cookies.get('user-id')
  //   this.$http.get(config.menu.host + '/user/' + user_id)
  //     .then(response => {
  //       this.User = response.data
  //       if (this.User.roles == 'OPERATOR') {
  //         this.opeartor_role = true
  //       }
  //       this.$http.get(config.menu.host + '/notification/' + this.User.org_id + '/' + this.User.roles).then(response => {
  //         loader.hide()
  //         this.notificationData = response.body
  //         this.isshowForm = false
  //         this.isshowgrid = true
          this.getUserInfo()
          this.getAllNotification()
          this.getAllSubscription()
          this.getLanguages()
          this.getProviders()
          this.getOrgInfo()
      //   }, error => {
      //     loader.hide()
      //     if (error && error.body) {
      //       Vue.notify({ text: error.body, type: 'error' })
      //     }
      //   })
      // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      showRemoveModal: false,
      show_image: false,
      show_subscriber: false,
      show_operators: false,
      show_operators_list: false,
      show_search: false,
      opeartor_role: false,
      content_allocated: false,
      image_url: '',
      genres: '',
      message: '',
      title: '',
      flag: '',
      subscriber_name: [],
      opeartor_name: [],
      subscriber_list: [],
      operator_list: [],
      language_list: [],
      delivery_type: '',
      operator_delivery_type: '',
      search_data: '',
      search_serviceType: '',
      search_language: '',
      search_provider: '',
      provider_id: '',
      select_search_title: '',
      content_id: '',
      show_search_title: false,
      ad_image_status: false,
      backend: '',
      User: [],
      Language: [],
      provider: [],
      Providers: [],
      ServiceType: [],
      ServiceResponse: [],
      ServiceTitle: [],
      notificationData: [],
      adimage_file: [],
      sub_list: [],
      ope_list: [],
      notification_type: ['Internal', 'External', 'Content'],
      notification_delivery_type: ['Multiple', 'All'],
      titleErrors: [],
      messageErrors: [],
      sendTypeErrors: [],
      searchProviderErrors: [],
      searchScErrors: [],
      searchLanErrors: [],
      searchLanErrors: [],
      searchDataErrors: [],
      selectSubscriberErrors: [],
      flagErrors: [],
      imageErrors: [],
      toastText: 'This toast is awesome!',
      toastDuration: 2500,
      toastIcon: 'fa-star-o',
      toastPosition: 'top-right',
      isToastFullWidth: false,
      operator_name: []
    }
  },
  computed: {
    formReady () {
      return !this.titleErrors.length && !this.messageErrors.length & !this.flagErrors.length
    },
    checkSearch () {
      return !this.searchLanErrors.length && !this.searchProviderErrors.length & !this.searchScErrors.length & !this.searchDataErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'title',
        width: '10px',
        title: 'Title',
      },
      {
        name: 'notification',
        width: '20px',
        title: 'Message',
      },
      {
        name: 'operator_name',
        width: '10px',
        title: 'Operator Name',
      },
      {
        name: 'flag',
        width: '10px',
        title: 'Type',
      },
      {
        name: 'createdAt',
        width: '20px',
        title: 'TimeStamp',
      },
      ]
    },
    filteredData () {
      return search(this.term, this.notificationData)
    },
  },
  methods: {
    getAllNotification () {
      this.$http.get(config.menu.host + '/notification/' + this.User.org_id + '/' + this.User.roles).then(response => {
        let index = 0
        this.notificationData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    getProviders () {
      this.$http.get(config.menu.host + '/provider').then(res => {
        this.Providers = res.body
        var data = this.Providers
        data = data.filter(function (pro, i) {
          if(!pro.iptv){
            pro.id = i + 1
            return pro
          }
        })
        this.provider = data
      })
    },
    getLanguages () {
      this.$http.get(config.menu.host + '/language').then(res => {
        var language = res.body
        this.language_list = res.body
        var data = []
        language.map(function (i) {
          data.push(i.language)
        })
        this.Language = data
      })
    },
    arrangeSubscriber () {
      var list = this.subscriber_name
      var data = []
      list.map(function (i) {
        data.push(i.mobile)
      })
      this.subscriber_list = data
    },
    arrangeOperator () {
      var list = this.opeartor_name
      var data = []
      list.map(function (i) {
        data.push(i.org_id)
      })
      this.operator_list = data
    },
    getAllSubscription () {
      this.$http.get(config.menu.host + '/subscription/' + 'all' + '/' + this.perPage + '/' + '0').then(res => {
        var list = res.body.rows
        list.map(function (pro, i) {
          pro.id = i + 1
          return pro
        })
        this.sub_list = list
      })
    },
    createNotification () {
      this.titleErrors = this.title ? [] : ['Title is required']
      this.messageErrors = this.message ? [] : ['Message is required']
      this.flagErrors = this.flag ? [] : ['Type is required']
      this.sendTypeErrors = this.delivery_type ? [] : ['Notification Type required']
      this.selectSubscriberErrors = this.subscriber_name ? [] : ['Select subscriber_name required']

      if (!this.formReady) {
        return
      }
      if(this.flag == 'Content' && !this.select_search_title){
        return Vue.notify({ text: 'Please select the search title', type: 'error' })
      }
      if(this.opeartor_role && this.delivery_type == ""){
        return Vue.notify({ text: 'Please  fill the delivery type', type: 'error' })
      }
      if(!this.opeartor_role && this.operator_delivery_type == ""){
        return Vue.notify({ text: 'Please  fill the delivery type', type: 'error' })
      }
      if(this.show_operators_list  && this.operator_list.length == 0){
	return Vue.notify({ text: 'Please  fill the form correctly', type: 'error' })
      }
      if (this.show_image) {
        if (!this.ad_image_status) {
          return Vue.notify({ text: 'Please  Upload image file', type: 'error' })
        }
      } else {
        if (!this.content_allocated) {
          return
        }
      }
      var payload = new FormData()
      payload.append('title', this.title)
      payload.append('notification', this.message)
      payload.append('operator_id', this.User.org_id)
      payload.append('flag', this.flag)
      payload.append('show_image', this.show_image)
      payload.append('operator_name', this.User.username)
      payload.append('role', this.User.roles)

      if (this.show_image) {
        payload.append('adimage_file', this.adimage_file[0])
      }
      if (this.show_subscriber) {
        payload.append('subscriber_name', this.subscriber_list)
        payload.append('delivery_type', this.delivery_type)
      } else {
        payload.append('delivery_type', this.delivery_type)
      }
      if (this.show_operators_list) {
        payload.append('show_operators_list', true)
        payload.append('operators_list', this.operator_list)
      } else {
        payload.append('show_operators_list', false)
      }
      if (this.User.roles == 'RESELLER') {
        payload.append('reseller_org_id', this.User.reseller_org_id)
      }
      if (this.show_search) {
        payload.append('delivery_type', this.delivery_type)
        payload.append('content_id', this.content_id)
        payload.append('sc_type', this.search_serviceType)
      }
      if(!this.opeartor_role){
         payload.append('delivery_type', this.operator_delivery_type)
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })

      this.$http.post(config.menu.host + '/notification', payload).then(responseData => {
        if (responseData && responseData.body) {
          this.toastText = responseData.body.data
          loader.hide()
          this.launchToast()
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getUserInfo () {
      const user_id = Vue.$cookies.get('user-id')
      this.$http.get(config.menu.host + '/user/' + user_id)
        .then(response => {
          this.User = response.data
          if (this.User.roles == 'OPERATOR') {
            this.opeartor_role = true
          }
        })
    },
    checkData () {
      if (this.flag == 'Content') {
        this.show_image = false
        this.show_subscriber = false
        this.show_search = true
        this.image_url = ''
      } else {
        this.show_image = true
        this.show_search = false
        this.show_search_title = false
      }
    },
    checkImage (type) {
      var image_file = this.adimage_file[0]
      if ((image_file !== undefined)) {
        var file_name = image_file.name.split('.').pop()
        if (file_name.toLowerCase() == 'jpg') {
          var width, height
          const img = new Image()
          width = '500'
          height = '300'
          const reader = new FileReader()
          reader.readAsDataURL(image_file)
          reader.onload = evt => {
            img.onload = () => {
              if ((img.width != width) && (img.height != height)) {
                this.adimage_file = ''
                this.adimage_file = []
                this.ad_image_status = false
                Vue.notify({ text: 'please check the images size', type: 'error' })
              }
            }
            this.ad_image_status = true
            img.src = evt.target.result
          }
        } else {
          this.ad_image_status = false
          this.adimage_file = ''
          Vue.notify({ text: 'please check the images format', type: 'error' })
        }
      }
    },
    getLanguagescode () {
      var input = this.search_language
      var data = this.language_list
      var short_code
      data.map(function (i) {
        if (i.language == input) {
          short_code = i.short_code
        }
      })
      this.search_language = short_code
    },
    getContentId () {
      var data = this.ServiceResponse
      var title = this.select_search_title
      var content_ids
      data.map(function (i) {
        if (i.title == title.title) {
          content_ids = i.content_id
        }
      })
      this.content_id = content_ids
      this.content_allocated = true
    },
    getServiceType () {
      var search_provider = this.search_provider
      var providers = this.Providers
      var lists = []
      var info = []
      var provider_id
      providers.map(function (i) {
        if (i.company_name == search_provider.company_name) {
          provider_id = i.provider_id
          lists.push(i.service_category)
        }
      })
      this.provider_id = provider_id
      var pars = JSON.parse(lists)
      pars.map(function (data) {
        info.push(data.id)
      })
      this.ServiceType = info
    },
    checkDeliveryType () {
      if (this.delivery_type == 'All') {
        this.show_subscriber = false
      } else {
        this.show_subscriber = true
      }
    },
    checkOperatorType () {
      if (this.operator_delivery_type == 'All') {
        this.show_operators_list = false
      } else {
        this.show_operators_list = true
      }
    },
    add () {
      this.title = ''
      this.message = ''
      this.flag = ''
      this.image_url = ''
      this.delivery_type = ''
      this.search_language = ''
      this.search_data = ''
      this.search_serviceType = ''
      this.search_provider = ''
      this.subscriber_name = []
      this.operator_list = []
      this.opeartor_name = []
      this.search_data = ''
      this.select_search_title = ''
      this.content_id = ''
      this.operator_delivery_type = ''
      this.provider_id,
      this.search_serviceType,
      this.search_language,
      this.search_data
      this.show_image = false
      this.show_search = false
      this.show_search_title = false
      this.show_subscriber = false
      this.content_allocated = false
      this.show_operators_list = false
      this.ad_image_status = false
      this.adimage_file = []
      this.titleErrors = []
      this.messageErrors = []
      this.flagErrors = []
      this.imageErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isCreate = true
      this.getOrgInfo()
	  },
	  list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllNotification()
    },
    getOrgInfo () {
      if (!this.opeartor_role) {
        this.$http.get(config.menu.host + '/notification/' + this.User.reseller_org_id + '/' + this.User.roles + '/' + this.User.org_id).then(response => {
          this.opeartorData = response.body
          var list = response.body
          list.map(function (pro, i) {
            pro.id = i + 1
            return pro
          })
          this.ope_list = list
        }, error => {
        })
      }
    },
    getContent () {
      this.searchProviderErrors = this.search_provider ? [] : ['Provider is required']
      this.searchScErrors = this.search_serviceType ? [] : ['Sc Type is required']
      this.searchLanErrors = this.search_language ? [] : ['Language is required']
      this.searchDataErrors = this.search_data ? [] : ['Search data is required']
      if (!this.checkSearch) {
        return
      }
      var payload = {
        search_provider_id: this.provider_id,
        search_serviceType: this.search_serviceType,
        search_language: this.search_language,
        search_data: this.search_data,
      }
      this.$http.post(config.menu.host + '/notification/search', payload).then(responseData => {
        this.ServiceResponse = responseData.body[0]
        var data = this.ServiceResponse
        var sea_data = []
        var vm = this
        data.map(function (pro, i) {
          pro.id = i + 1
          if(vm.search_serviceType == 'WS'){
            pro.title = pro.original_title
          }
          return pro
        })
        this.ServiceTitle = data
        this.show_search_title = true
      })
    },
    launchToast () {
      this.showToast(
        this.toastText,
        {
          icon: this.toastIcon,
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        },
      )
    },
	  search: debounce(function (term) {
	   	this.term = term
	  }, 400),
  },
}
</script>
